 // Packages
import styled from 'styled-components'

export const StyledBasiPageContent = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledBasicPageWrapper = styled.div`
  padding: 3rem 1rem;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 90%;        
  }
`

export const StyledTitle = styled.h1`
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`

export const StyledParagraph = styled.p`  
  font-weight: 400;
  margin: 1rem 0;
  line-height: 1.3;
  text-align: left;
`

export const StyledStrong = styled.strong`
  font-weight: 700;
`

export const StyledStrongUppercase = styled(StyledStrong)`
  text-transform: uppercase;
`

export const StyledUl = styled.ul``

export const StyledLi = styled.li`
  font-weight: 400;  
  line-height: 1.3;
  text-align: left;
`