// Packages
import React from 'react'

// Components
import AppLayout from '../../layouts/AppLayout'
import SEO from '../../components/presentational/SEO'
import BasicPageContent from '../../components/presentational/BasicPageContent'

const NoticeOfPrivacyPage = () => {
  return (
    <AppLayout>
      <SEO title='Conoce nuestro aviso de privacidad' />
      <BasicPageContent />
    </AppLayout>
  )
}

export default NoticeOfPrivacyPage
