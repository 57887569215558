// Packages
import React from 'react'

// Styled components
import { StyledBasiPageContent, StyledBasicPageWrapper, StyledTitle, StyledParagraph, StyledStrongUppercase, StyledStrong, StyledUl, StyledLi } from './styledComponents'

const BasicPageContent = () => {
  return (
    <StyledBasiPageContent>
      <StyledBasicPageWrapper>
        <StyledTitle>aviso de privacidad</StyledTitle>
        <StyledParagraph>
          <StyledStrongUppercase>LABORATORIO ALVAREZ,</StyledStrongUppercase> con domicilio en Ave. Alvaro Obregón No. 720 sur Cd. Madero, es el responsable del uso y protección de sus datos personales y al respecto le informamos lo siguiente:         
        </StyledParagraph>
        <StyledParagraph>
          <StyledStrong>
            ¿Para qué fines utilizaremos sus datos personales?           
          </StyledStrong>
        </StyledParagraph>
        <StyledParagraph>
          Los datos personales que recabamos de usted, mediante llamadas telefónicas, página web, correos electrónicos, de forma personal o cualquier otro medio, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:        
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            - Proveer el servicio de laboratorio de análisis clínicos y/o gabinete, auxiliares al diagnóstico médico.
          </StyledLi>
          <StyledLi>
            - Generar una base de datos que facilite la localización de sus resultados y garantizar la confidencialidad de estos.
          </StyledLi>
          <StyledLi>
            - Atención a servicios administrativos de forma interna para otorgarle mejores resultados en nuestros servicios.
          </StyledLi>
        </StyledUl>        
        <StyledParagraph>
          De manera adicional, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:         
        </StyledParagraph>
        <StyledUl>
          <StyledLi>
            - Evaluación de calidad en la atención y el servicio.
          </StyledLi>
          <StyledLi>
            - Difusión de información relacionada con los servicios.
          </StyledLi>
          <StyledLi>
            - Mercadotecnia.
          </StyledLi>
          <StyledLi>
            - De publicidad.
          </StyledLi>
          <StyledLi>
            - Prospección comercial.
          </StyledLi>
        </StyledUl>         
      </StyledBasicPageWrapper>
    </StyledBasiPageContent>
  )
}

export default BasicPageContent
